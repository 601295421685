<template>
    <section class="services py-0 bg-image-container">
      <div class="gradient-overlay">
        <div class="container py-5">
          <h2 class="mb-4 text-white">The Beauty of a Wedding in Paradise</h2>
  
          <!-- Content Rows -->
          <div class="row g-4">
            <div class="col-md-12">
              <h4 class="mb-3 text-white">A Dream Wedding on a Private Island</h4>
              <p class="text-white">
                Picture the golden sands beneath your feet, the turquoise waters gently lapping at the shore, and the warm sun illuminating your special day. A wedding on your private island offers a breathtaking blend of natural beauty and luxurious touches, making it the perfect setting for your romantic union.
              </p>
              <p class="text-white">
                Unlike traditional wedding venues, the serene atmosphere of a tropical paradise allows you to exchange vows amidst the soothing sounds of nature, with vibrant floral arrangements and the refreshing ocean breeze creating an unforgettable celebration.
              </p>
            </div>
          </div>
  
          <div class="row g-4 mt-4">
            <div class="col-md-12">
              <h4 class="mb-3 text-white">Stress-Free Planning</h4>
              <p class="text-white">
                Planning a wedding can feel overwhelming, but with our experienced team by your side, every detail will be flawlessly managed. From securing legal documents to organizing stunning floral arrangements and coordinating the perfect ceremony, we handle everything so you can focus on what truly matters: your love story.
              </p>
              <p class="text-white">
                Our commitment to excellence ensures that your wedding day is seamless, magical, and tailored to your unique preferences. Simply share your vision with us, and we’ll take care of the rest.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  /* Section Background */
  .bg-image-container {
    background-image: url('@/assets/bg/103751.jpg'); /* Background image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 50px 0;
  }
  
  /* Gradient Overlay */
  .gradient-overlay {
    position: relative;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(30, 25, 20, 0.1) 0%,      /* Darker sand tone at the top */
      rgba(30, 25, 20, 0.3) 20%,     /* Lighter sand tone in the middle */
      rgba(30, 25, 20, 0.3) 80%,     /* Lighter sand tone in the middle */
      rgba(30, 25, 20, 0.1) 100%     /* Darker sand tone at the bottom */
    );
  }
  
  .gradient-overlay::before,
  .gradient-overlay::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    backdrop-filter: blur(3px); /* Subtle blur at the top */
    z-index: -1;
  }
  
  .gradient-overlay::after {
    top: auto;
    bottom: 0;
    height: 20%;
    backdrop-filter: blur(5px); /* Subtle blur at the bottom */
    z-index: -1;
  }
  
  /* Text Styling */
  .services h2,
  .services h4 {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); /* Subtle shadow for better readability */
  }
  
  .services h2 {
    margin-bottom: 30px;
    font-family: "MonteCarlo", cursive;
  }
  
  .services h4 {
    margin-bottom: 15px;
  }
  
  .services p {
    font-family: 'Roboto', sans-serif;
    color: white;
    line-height: 1.8;
    font-size: 0.95rem;
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.3);
  }
  </style>
  