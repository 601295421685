<template>
  <section class="contact bg-image-container py-0">
    <div class="gradient-overlay py-5">
      <div class="container">
        <h2 class="text-center mb-4">Get in Touch</h2>

        <div class="row g-4">
          <!-- Contact Form -->
          <div class="col-md-6">
            <h4 class="mb-3">Send Us a Message</h4>
            <form @submit.prevent="submitForm">
              <div class="mb-3">
                <label for="name" class="form-label styled-label">Your Name</label>
                <input
                  type="text"
                  id="name"
                  class="form-control styled-input"
                  v-model="formData.name"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label styled-label">Your Email</label>
                <input
                  type="email"
                  id="email"
                  class="form-control styled-input"
                  v-model="formData.email"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="message" class="form-label styled-label">Your Message</label>
                <textarea
                  id="message"
                  rows="4"
                  class="form-control styled-input"
                  v-model="formData.message"
                  placeholder="Write your message"
                  required
                ></textarea>
              </div>
              <button type="submit" class="btn btn-primary w-100 styled-btn" :disabled="isSubmitting">
                <span v-if="isSubmitting" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                {{ isSubmitting ? "Sending..." : "Send Message" }}
              </button>
            </form>

            <!-- Feedback Messages -->
            <div v-if="successMessage" class="alert alert-success mt-3" role="alert">
              {{ successMessage }}
            </div>
            <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
              {{ errorMessage }}
            </div>
          </div>

          <!-- Contact Information -->
          <div class="col-md-6">
            <h4 class="mb-3">Contact Information</h4>
            <ul class="list-unstyled mb-4">
              <li class="mb-3">
                <i class="fas fa-phone me-2 text-primary"></i>
                <strong>Phone:</strong> <a href="tel:+6421 2663644" class="text-decoration-none">+6421 2663644</a>
              </li>
              <li class="mb-3">
                <i class="fas fa-envelope me-2 text-primary"></i>
                <strong>Email:</strong> <a href="mailto:dreamweddingaitutaki@yahoo.com" class="text-decoration-none">dreamweddingaitutaki@yahoo.com</a>
              </li>
            </ul>

            <!-- Social Media Buttons -->
            <h4 class="mb-3">Follow Us</h4>
            <div class="d-flex gap-2 justify-content-center">
              <a
                href="https://www.instagram.com/dreamwedding_aitutaki/"
                target="_blank"
                class="btn btn-outline-primary styled-social-btn"
              >
                <i class="fab fa-instagram"></i> Instagram
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61568286101559"
                target="_blank"
                class="btn btn-outline-primary styled-social-btn"
              >
                <i class="fab fa-facebook-f"></i> Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      isSubmitting: false,
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true;
      this.successMessage = "";
      this.errorMessage = "";

      try {
        const response = await fetch("https://formspree.io/f/xqakgdvg", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });

        if (response.ok) {
          this.successMessage = "Your message has been sent successfully! Thank you for reaching out.";
          this.formData.name = "";
          this.formData.email = "";
          this.formData.message = "";
        } else {
          this.errorMessage = "Something went wrong while sending your message. Please try again later.";
        }
      } catch (error) {
        this.errorMessage = "Unable to send your message. Please check your internet connection and try again.";
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>




<style scoped>

.spinner-border {
  color: white;
}
.bg-image-container {
  /* background-image: url('@/assets/bg/103786.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 50px 0;
}
.gradient-overlay {
  position: relative;
  z-index: 1;
  backdrop-filter: blur(5px)
}
/* General Styles */
.contact h2, .contact h4 {
  font-family: "MonteCarlo", cursive;
  color: #005f9e;
  font-weight: bold;
}

.contact h2 {
  margin-bottom: 30px;
}

.contact h4 {
  margin-bottom: 15px;
}

/* Form Styling */
.styled-label {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: #005f9e;
  font-weight: 600;
  margin-bottom: 5px;
}

.styled-input {
  border: 1px solid #b0d0ea;
  border-radius: 5px;
  padding: 10px;
  font-size: 0.95rem;
}

.styled-input:focus {
  border-color: #005f9e;
  box-shadow: none;
}

.styled-btn {
  background-color: #005f9e;
  border-color: #005f9e;
}

.styled-btn:hover {
  background-color: #004080;
  border-color: #004080;
}

/* Social Media Buttons */
.styled-social-btn {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.styled-social-btn i {
  font-size: 1.1rem;
}

.styled-social-btn:hover {
  color: white;
  background-color: #005f9e;
  transform: scale(1.05);
}

/* Contact Information */
.contact ul li {
  font-size: 0.95rem;
  line-height: 1.8;
}

.contact ul a {
  color: #005f9e;
  text-decoration: none;
}

.contact ul a:hover {
  text-decoration: underline;
}
</style>
