import { createApp } from 'vue'
import App from './App.vue'



import AOS from 'aos';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import '@fortawesome/fontawesome-free/css/all.css';


console.log('jQuery:', typeof $); // Should log "function"
console.log('jQuery.fn:', typeof $.fn); // Should log "object"



createApp(App).mount('#app')
// Initialize AOS
AOS.init({
duration: 1000, // Animation duration in milliseconds
easing: 'ease-in-out', // Easing function
once: true, // Whether animation should happen only once
});