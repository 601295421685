<template>
  <section class="faq py-5">
    <div class="container">
      <h2 class="text-center mb-4">Frequently Asked Questions</h2>
      
      <!-- FAQ Accordion -->
      <div class="accordion" id="faqAccordion">
        <div class="accordion-item mb-0" v-for="(faq, index) in faqs" :key="index">
          <h2 class="accordion-header" :id="'heading' + index">
            <button
              class="accordion-button btn collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + index"
              :aria-expanded="false"
              :aria-controls="'collapse' + index"
            >{{ faq.question }}</button>
          </h2>
          <div
            class="accordion-collapse collapse"
            :id="'collapse' + index"
            :aria-labelledby="'heading' + index"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              {{ faq.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: "What is included in the wedding package?",
          answer:
            "Our package includes flights, accommodation, ceremony arrangements, floral decorations, and much more. See the full list above for details.",
        },
        {
          question: "Can we customize the package?",
          answer:
            "Yes! Talk to us and we will tailor your experience by adding or removing services to suit your desires or your budget. Subject to availability.",
        },
        {
          question: "What is the cancellation policy?",
          answer:
            "We offer flexible cancellation terms. However, deposits are non-refundable. Contact us for detailed terms and conditions.",
        },
        {
          question: "Do you offer child-friendly options?",
          answer:
            "Yes, we can provide child-friendly options to ensure they enjoy the event too.",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* General FAQ Section */
.faq {
  padding: 50px 20px;
}

.faq h2 {
  font-family: "MonteCarlo", cursive;
  font-size: 3rem;
  color: #005f9e; /* Blue */
  font-weight: bold;
  margin-bottom: 30px;
}

/* Accordion Styles */
.accordion {
  margin: 0;
  padding: 0;
}

.accordion-item {
  border: none;
  background-color: transparent;
  margin: 0; /* Remove margin between items */
  padding: 0; /* Remove padding for seamless flow */
}

.accordion-button {
  background-color: #dbeaf6; /* Light blue */
  color: #005f9e; /* Dark blue */
  border: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-bottom: 1px solid #b0d0ea; /* Light divider for structure */
}

.accordion-button:hover {
  background-color: #005f9e; /* Darker blue on hover */
  color: #fff; /* White text */
}

.accordion-button:focus {
  box-shadow: none; /* Remove default focus shadow */
}

.accordion-body {
  font-family: 'Roboto', sans-serif;
  font-size: 0.95rem;
  color: #333;
  line-height: 1.6;
  padding: 10px 15px;
  border-bottom: 1px solid #b0d0ea; /* Divider between items */
  margin: 0; /* Remove default margin */
}

.accordion-item:last-child .accordion-body {
  border-bottom: none; /* Remove border for the last item */
}
</style>
