<template>
  <section class="hero-carousel">
    <div class="owl-carousel owl-theme">
      <div
        class="item hero-slide"
        v-for="(slide, index) in slides"
        :key="index"
        :style="{ backgroundImage: `url(${slide.image})` }"
      >
        <div class="hero-content">
          <h1>{{ slide.title }}</h1>
          <p>{{ slide.message }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        {
          title: "Tie the Knot in Paradise",
          message: "Say 'I do' amidst golden sands and shimmering turquoise waters, a setting made for love.",
          image: require('@/assets/hero/arc.jpg'),
        },
        {
          title: "Escape to Romantic Bliss",
          message: "Honeymoon Island awaits you—a haven of beauty and serenity for your unforgettable vows.",
          image: require('@/assets/hero/hero1.jpg'),
        },
        {
          title: "Where Elegance Meets Nature",
          message: "Let breathtaking views and tranquil surroundings set the stage for your special day.",
          image: require('@/assets/hero/hero3.jpg'),
        },
        {
          title: "A Ceremony Like No Other",
          message: "Celebrate your love in an unmatched blend of sophistication and natural beauty.",
          image: require('@/assets/hero/hero42.jpg'),
        },
      ]
    };
  },
  mounted() {
    $(this.$el)
      .find('.owl-carousel')
      .owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 7500,
        dots: true,
        nav: false,
      });
  },
};
</script>

<style scoped>
.hero-carousel {
  height: 100vh;
  overflow: hidden;
}

.hero-slide {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3), /* Darker black at the top */
    rgba(50, 50, 50, 0.5) /* Lighter greyish black at the bottom */
  );
  backdrop-filter: blur(3px);
  border-radius: 10px;
  padding: 20px 30px;
  max-width: 500px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.hero-content p {
  font-style: italic;
}

.hero-title {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Subtle shadow for readability */
  margin-bottom: 15px;
}

.hero-description {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  line-height: 1.6;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Subtle shadow */
}

</style>
