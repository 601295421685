<template>
  <section class="testimonials py-5">
    <div class="container text-center position-relative">
      <h2 class="mb-4">What Couples Are Saying</h2>

      <!-- Owl Carousel -->
      <div class="owl-carousel owl-theme">
        <div class="testimonial-item" v-for="(testimonial, index) in testimonials" :key="index">
          <p class="quote">
            "{{ testimonial.quote }}"
          </p>
          <h4 class="name">- {{ testimonial.name }}</h4>
          <p class="location">{{ testimonial.location }}</p>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <button class="carousel-nav-btn prev" @click="prevSlide">
        <i class="fas fa-chevron-left"></i>
      </button>
      <button class="carousel-nav-btn next" @click="nextSlide">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          quote: "Our wedding on Honeymoon Island was a dream come true. Everything was perfect!",
          name: "Emily & James",
          location: "Auckland, New Zealand",
        },
        {
          quote: "The entire experience was seamless. From the decorations to the views, it felt magical.",
          name: "Sophia & Liam",
          location: "Sydney, Australia",
        },
        {
          quote: "We couldn’t have asked for a more romantic and intimate setting.",
          name: "Mia & Ethan",
          location: "Los Angeles, USA",
        },
      ],
    };
  },
  mounted() {
    const owl = $(this.$el).find('.owl-carousel').owlCarousel({
      items: 1, // Show one item at a time
      loop: true, // Enable infinite loop
      autoplay: true, // Enable autoplay
      autoplayTimeout: 5000, // 5 seconds per slide
      dots: true, // Show navigation dots
    });

    // Save reference to the carousel for button controls
    this.owlCarousel = owl;
  },
  methods: {
    prevSlide() {
      if (this.owlCarousel) this.owlCarousel.trigger('prev.owl.carousel');
    },
    nextSlide() {
      if (this.owlCarousel) this.owlCarousel.trigger('next.owl.carousel');
    },
  },
};
</script>

<style scoped>
.testimonials {
  background: none;
  padding: 50px 0;
}

.testimonials h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #2a6f97;
  margin-bottom: 30px;
  font-family: "MonteCarlo", cursive;
}

.testimonial-item {
  padding: 20px;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.testimonial-item .quote {
  font-style: italic;
  font-size: 1.2rem;
  color: #555;
}

.testimonial-item .name {
  font-size: 1.1rem;
  color: #2a6f97;
  font-weight: bold;
}

.testimonial-item .location {
  font-size: 0.9rem;
  color: #999;
}

/* Navigation Buttons */
.carousel-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.carousel-nav-btn.prev {
  left: -60px; /* Adjust distance from the carousel */
}

.carousel-nav-btn.next {
  right: -60px; /* Adjust distance from the carousel */
}

.carousel-nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
