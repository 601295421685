<template>
    <footer>
      <p>&copy; 2024 Honeymoon Private Island. All rights reserved.</p>
      <p>Note: A 25% surcharge applies to Saturdays & public holidays. No Sunday weddings.</p>
    </footer>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  footer {
    padding: 20px;
    background-color: #333;
    color: #fff;
    text-align: center;
  }
  </style>
  